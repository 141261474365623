﻿$spacing: 1.5rem;

$header-logo-height: 60px;
.app-wrapper {

    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .content-wrapper {
        width: 100%;
        padding: 0 $spacing;

        &>.content-body {
            margin: 0 auto;
            width: 100%;

            @include fullhd  {
                width: 1150px;
            }

            @include widescreen-only  {
                width: 950px;
            }

            @include desktop-only {
                width: 800px;
            }

            @include tablet-only {
                width: 700px;
            }
        }
    }

    header {

        background-color: white;
        z-index: 199;

        position: sticky;
        top: 0;
        width: 100%;
        align-self: flex-start;

        .content-wrapper .content-body {

            display: flex;
            flex-direction: column;
            max-height: 100vh;

            // Der Header soll sich auf Tablets schon wie mobile verhalten
            @include touch {
                padding: 0;

                & > * {
                    width: 100%;
                }
            }

            .logoblock {

                padding: calc($spacing / 2) 0;
                display: flex;
                flex-direction: row;
                justify-content: space-between;

                background-position: left;
                background-repeat: no-repeat;

                .logo {
                    display: block;
                    height: 60px;
                    width: 185px;

                    background-position: center;
                    background-repeat: no-repeat;
                    background-image: url(public/logo-orange.svg);
                }

                .dropdown-trigger {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    height: $header-logo-height;
                    width: $header-logo-height;

                    color: black;
                    font-size: 1.5rem;
                }
            }

            .subMenu {
                position: absolute;
                right: 10px;
                top: 0px;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: end;
                z-index: 100; // vor dem Mainmenü

                color: $muted-grey;

                a {
                    color: $muted-grey;
                    margin-right: 20px;

                    &.is-active {
                        color: $bfsorange;
                    }
                }

                i {
                    margin-left: 5px;
                    margin-right: 10px;
                    line-height: unset;
                }

                p {
                    max-width: 230px;

                    @media screen and (max-width: 768px) {
                        max-width: 150px;
                    }
                    @media screen and (max-width: 450px) {
                        width: 0px;
                    }

                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .dropdown-trigger {
                    display: flex;
                }

                .dropdown-menu {
                    min-width: 2rem;
                }
            }

            .userwelcome-mobile {
                font-size: 1.5rem;
                padding: 50px;
            }

            .main-menu {

                overflow-x: visible;
                overflow-y: visible;

                ul {
                    border-color: transparent;
                    max-width: 100%;
                    flex-wrap: wrap;
                    z-index: 199; // Vor dem Modal

                    li {
                        &.is-active {
                            a {
                                border-color: $bfsorange;

                                &:hover {
                                    color: $bfsorange;
                                }
                            }
                        }

                        a {
                            &:hover {
                                border-color: $chosen-dark-grey;
                                color: $chosen-dark-grey;
                            }

                            border-bottom-width: 2px;
                            border-color: white;
                        }
                    }

                    .dropdown-menu {
                        a.dropdown-item {
                            color: black;

                            &:hover {
                                color: black;
                            }

                            &.is-active {
                                color: white;
                            }
                        }
                    }
                }
            }

            .mobile-menu-wrapper {

                flex-shrink: 1;
                overflow-y: auto;

                .mobile-menu {
                    ul {
                        li {
                            padding: .2rem 0;

                            user-select: none; // Verhindert, das Text bei der Auswahl eines Menüeintrages selektiert wird

                            &.is-active {
                                a {
                                    background-color: $bfsorange;
                                    color: $dropdown-item-active-color;
                                }
                            }

                            &.expanded {
                                label {
                                    .icon {
                                        transform: rotate(90deg);
                                    }
                                }
                            }

                            label {
                                padding: 0.375rem 1rem;
                                font-weight: bold;
                                font-size: .8em;
                                color: $chosen-dark-grey;

                                .icon {
                                    transform: rotate(0deg);
                                    transition: transform .5s;

                                    margin-left: 0;
                                    padding-left: 0;

                                    width: 1rem;
                                }
                            }
                        }

                        ul {
                            margin-left: 1.5rem;
                        }
                    }
                }
            }
        }
    }

    .logo-container-big-cover {
        background-color: $coverimagebackground;
        line-height: 0; // Verhindert leeren Platz am Ende
    }

    main {
        flex-grow: 1;

        background-color: $mainbackground;
        padding-bottom: $spacing;
    }

    footer {
        padding: 7px;

        .content-body {

            font-size: 0.7rem;
            @media screen and (min-width: 768px) {
                font-size: 0.85rem;
            }

            margin-right: 10px;
            margin-left: 10px;

            margin-bottom: 5px;

            justify-content: space-between;
            display: flex;

            align-items: flex-end;

            a {
                color: $muted-grey;

                &.space-right {
                    margin-right: 20px;
                }
            }
        }
    }
}
