﻿.pwa-banner {
    width:100%;
    padding: .5rem .2rem;
    background-color: $bfsorange;

    position: relative;
    z-index: 1;

    color: white;

    .pwa-installer {
        width: 100%;

        display: flex;
        flex-direction: row;
        align-items: center;

        &>* {
            margin: 0 .3rem;
        }

        .cancel-icon {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .logo {
            width: 60px;
            height: 60px;
            border: 1px solid white;
            border-radius: 15px;
            padding: 5px;
        }

        .name {
            font-weight: lighter;

            .pwa-subtitle {
                @media only screen and (max-width: 450px) {
                    display: none;
                }
            }
        }

        .install {
            flex-grow: 1;
            display: flex;
            justify-content: end;
            .button.is-white:not(.is-loading) {
                color: white !important;

                &:focus,
                &:hover {
                    color: $bfsorange !important;
                }
            }
        }
    }
}

$pwa-install-hint-margin: 1rem;
$pwa-install-hint-content-padding: 1.5rem 2rem;

.pwa-install-hint {

    position: absolute;
    top: 0;
    left: 0;

    width: 100vw;
    height: 100vh;

    z-index: 1000;

    visibility: hidden;

    .pwa-install-hint-backdrop {
        background-color: #0000;
        transition: background-color .2s linear;

        width: 100%;
        height: 100%;
    }

    .pwa-install-hint-content {
        position: fixed;
        bottom: 0;
        left: 0;

        transform: translateY(150%);
        transition: all .2s ease-in-out;

        width: calc(100% - 2 * $pwa-install-hint-margin);

        margin: $pwa-install-hint-margin;

        border-radius: 10px;

        background-color: #EDECEFFF;

        .pwa-install-hint-header {
            padding: $pwa-install-hint-content-padding;
            display: flex;
            align-items: center;

            font-size: 1.2rem;

            .logo {
                height: 40px;
                margin-right: 20px;
            }
        }

        hr {
            background-color: gray;
            margin: 0;
            height: 1px;
        }

        .pwa-install-hint-body {
            padding: $pwa-install-hint-content-padding;

            ol {
                li {
                    margin: .5rem 2rem;

                    .icon {
                        background-color: white;
                        border: 1px solid lightgray;
                        border-radius: 5px;
                        padding: .8rem;
                    }
                }
            }
        }
    }

    &.active {
        visibility: visible;

        .pwa-install-hint-backdrop {
            background-color: #000A;
        }

        .pwa-install-hint-content {
            transform: translateY(0);
        }
    }
}
