/*! _colors.scss | Nephos | CSS Ninja */

/* ==========================================================================
Nephos global colors 
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Variables
=============================================================================
***/

/* ==========================================================================
0. Variables
========================================================================== */

$white: #fff;
$smoke-white: #fcfcfc;
$placeholder: #cecece;
$light-grey: #f9f9f9;
$muted-grey: #999;
$muted-grey-bold: #828282;
$title-grey: #A9ABAC;
$fade-grey: #ededed;
$accent-grey: #ccc;
$dark-text: #4a4a4a;
$chosen-grey: #e0e0e0;
$chosen-dark-grey: #66676b;
$grey-6: #666;
$section-grey: #fcfcfc;

$sidebar: #344258;
$red: #FF7273;
$blue: #039BE5;
$green: #00b289;
$orange: #eda514;