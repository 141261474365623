﻿.mobile-header-faq {
    .column.is-full {

        width: 100%;

        form {
            width: 100%;

            .field {
                width: 100%;
            }
        }
    }
}
