/*! _quickview.scss | Nephos | CSS Ninja */

/* ==========================================================================
Quickview styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Flying Action Button
1. Categories right Sidebar
2. Shop Dark Sidebar
3. Filters Sidebar
4. Cart Sidebar
5. Sidebar Header
=============================================================================
***/

/* ==========================================================================
0. Flying Action Button
========================================================================== */

//Fixed categories FAB
.menu-fab {
    position: fixed;
    right: 30px;
    top: 12px;
    width: 50px;
    height: 50px;
    background: $white;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 10;
    box-shadow: 0 3px 10px 4px rgba(0,0,0,.04);
    transition: all .4s;
    transform: translateX(0) rotate(0);
    opacity: 1;
    //Pushes the button when filters and cart are opened
    &.dismissed {
        transform: translateX(300px) rotate(360deg);
        opacity: 0;
    }
    a {
        padding: 10px 0;
    }
    //Animated hamburger icon
    .menu-toggle  {
        font-size: 20px; 
        color: #666; 
        line-height: 48px; 
        text-align: center; 
        background: transparent; 
        display: block; 
        width: 24px;
        height: 26px; 
        cursor: pointer; 
        padding: 0; 
        margin: 0 14px; 
        transition: opacity 0.4s;
        opacity: 1;
        position: relative;
        transform: scale(0.7);
        //toggleable icon
        .icon-box-toggle  {
            height: 100%;
            width: 100%; 
            background: tranparent; 
            position: relative;
            display: block; 
            //Active state
            &.active > span.rotate {
                -webkit-transform: rotate(90deg);
                -moz-transform:translate(0px, 0px) rotate(90deg);
                -ms-transform:translate(0px, 0px) rotate(90deg);
                -o-transform:translate(0px, 0px) rotate(90deg);
                transform:translate(0px, 0px) rotate(90deg);
            }
            //Active hamburger lines
            &.active > span > i.icon-line-center{
                visibility: hidden; 
                width: 1px; 
                height: 3px; 
                left: 70%;
            }
            &.active > span > i.icon-line-bottom {
                margin: -1.5px 0 0 -10px;
                left: 50%;
                top: 50%; 
                -webkit-transform: rotate(135deg);
                -moz-transform:translate(0px, 0px) rotate(135deg);
                -ms-transform:translate(0px, 0px) rotate(135deg);
                -o-transform:translate(0px, 0px) rotate(135deg);
                transform:translate(0px, 0px) rotate(135deg);
            }
            &.active  > span > i.icon-line-top {
                margin: -1.5px 0 0 -10px;
                left: 50%;
                top: 50%; 
                -webkit-transform: rotate(45deg);
                -moz-transform:translate(0px, 0px) rotate(45deg);
                -ms-transform:translate(0px, 0px) rotate(45deg);
                -o-transform:translate(0px, 0px) rotate(45deg);
                transform:translate(0px, 0px) rotate(45deg);
            }
        }
        //hamburger lines
        .icon-line-center {
            position: absolute; 
            width: 20px; 
            height: 2px; 
            background: $primary; 
            margin: -1.5px 0 0 -10px;
            left: 50%;
            top: 50%; 

            -webkit-transition: all .2s ease ;
            -moz-transition: all .2s ease ;
            -o-transition: all .2s ease ;
            transition: all .2s ease ;	
        }
        .icon-line-top {
            position: absolute; 
            width: 20px; 
            height: 2px; 
            background: $primary; 
            margin: -2px 0 0 -10px;
            left: 50%;
            top: 30%; 
            -webkit-transition: all .2s ease ;
            -moz-transition: all .2s ease ;
            -o-transition: all .2s ease ;
            transition: all .2s ease ;	
        }
        .icon-line-bottom {
            position: absolute; 
            width: 20px; 
            height: 2px; 
            background: $primary; 
            margin: 1.5px 0 0 -10px;
            left: 50%;
            top: 60%; 
            -webkit-transition: all .2s ease ;
            -moz-transition: all .2s ease ;
            -o-transition: all .2s ease ;
            transition: all .2s ease ;	
        }
    }
}

/* ==========================================================================
1. Categories right Sidebar
========================================================================== */

.category-quickview {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 260px;
    background-color: $white;
    box-shadow: -2px 0px -2px 0px rgba(0,0,0,0.06);
    border-left: 1px solid #ededed;
    z-index: 9;
    transform: translateX(261px);
    transition: all .3s;
    //Pushed state
    &.is-active {
        transform: translateX(0);
    }
    &.is-pushed-mobile {
        transform: translateX(-500px) !important;
    }
    .inner {
        position: relative;
        height: 100%;
        //Menu and items
        .category-menu {
            padding: 80px 0;
            li {
                height: 80px;
                display: flex;
                align-items: center;
                justify-content: flex-end;
                padding: 0 34px;
                a {
                    font-family: 'Raleway', sans-serif;
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    color: $dark-text;
                    span {
                        min-width: 150px;
                        text-align: right;
                        padding: 0 10px;
                        text-transform: uppercase;
                        font-weight: 600;
                        font-size: 76%;
                        transition: color .4s;
                    }
                    img, svg {
                        max-height: 40px;
                    }
                    svg {
                        height: 40px;
                        width: 40px;
                        fill: $accent-grey;
                        transition: all .3s;
                    }
                    &:hover {
                        span {
                            color: $primary;
                        }
                        svg {
                            fill: $primary;
                        }
                    }
                }
            }
        }
        //All categories link
        .all-categories {
            position: absolute;
            bottom: 52px;
            width: 100%;
            text-align: center;
            a {
                text-transform: uppercase;
                font-size: 75%;
                font-weight: 500;
            }
            .centered-divider {
                width: 100px;
                height: 1px;
                border-bottom: 2px solid $primary;
                margin: 0 auto;
                padding-top: 8px;
            }
        }
    }
}

/* ==========================================================================
2. Shop Dark Sidebar
========================================================================== */

.shop-quickview {
    position: fixed;
    top: 0;
    left: 84px;
    height: 100%;
    width: 280px;
    background-color: $sidebar;
    background-size: cover;
    background-position: bottom;
    box-shadow: -2px 0px -2px 0px rgba(0,0,0,0.06);
    z-index: 3;
    transform: translateX(-285px);
    transition: all .3s;
    overflow: hidden;
    //Pushed State
    &.is-pushed-mobile {
        transform: translateX(-500px) !important;
        left: 0 !important;
    }
    //Pushed state
    &.is-active {
        transform: translateX(-5px);
    }
    .inner {
        position: relative;
        height: 100%;
        background-color: rgba(43, 56, 76, 0.95);
        //header
        .quickview-header {
            height: 84px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid lighten($sidebar, 5%);
            span {
                cursor: pointer;
            }
            h2 {
                position: relative;
                top: -4px;
                font-family: 'Raleway', sans-serif;
                font-size: 1.15rem;
                font-weight: 500;
                color: $white;
                text-transform: uppercase;
                letter-spacing: 6px;
                padding-left: 20px;
            }
            svg {
                margin-right: 20px;
                width: 20px;
                height: 20px;
                stroke: $white;
            }
        }
        //Body
        .shop-menu, .user-profile {
            li {
                width: 100%;
                height: 80px;
                display: flex;
                align-items: center;
                justify-content: flex-start;
                border-bottom: 1px solid lighten($sidebar, 5%);
            }
        }
        //shop menu
        .shop-menu {
            li {
                cursor: pointer;
                &:hover a {
                    opacity: 0.8;
                    svg {
                        transform: translate3d(0,0,0) rotate(0);
                        opacity: 1;
                    }
                }
            }
            a {
                width: 100%;
                padding: 0 20px;
                font-family: 'Raleway', sans-serif;
                font-weight: 500 !important;
                text-transform: uppercase;
                letter-spacing: 3px;
                font-size: 0.75rem;
                color: $white;
                display: flex;
                justify-content: space-between !important;
                align-items: center;
                svg {
                    width: 18px;
                    height: 18px;
                    margin: 0;
                    opacity: 0;
                    transition: all .3s;
                    transform: translate3d(100px,0,0) rotate(360deg);
                }
            }
        }
        //Customer avatar
        .user-profile {
            position: absolute;
            bottom: 0;
            padding: 0;
            //List item
            li {
                border-bottom: none !important;
                img {
                    max-height: 40px;
                    max-width: 40px;
                    border-radius: 50%;
                    margin-left: 20px;
                }
                a {
                    width: 100%;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    .user {
                        padding-left: 10px;
                        color: $white;
                        span {
                            display: block;
                            font-family: 'Raleway', sans-serif;
                            &:first-child {
                                text-transform: uppercase;
                                font-weight: 600;
                                font-size: 0.7rem;
                                letter-spacing: 3px;
                            }
                            &:nth-child(2) {
                                font-size: 0.8rem;
                                small {
                                    font-size: 0.6rem;
                                    font-weight: 500;
                                    text-transform: uppercase;
                                    letter-spacing: 1px;
                                }
                            }
                        }
                    }
                    &:hover span {
                        opacity: 0.8;
                    }
                }
            }
        }
    }
}

/* ==========================================================================
3. Filters Sidebar
========================================================================== */

.filters-quickview {
    position: fixed;
    top: 0;
    left: 84px;
    height: 100%;
    width: 320px;
    background-color: $white;
    background-size: cover;
    background-position: bottom;
    box-shadow: 2px 0px 2px 0px rgba(0,0,0,0.02);
    z-index: 3;
    transform: translateX(-325px);
    transition: all .3s;
    //Mobile pushed state
    &.is-pushed-mobile {
        transform: translateX(-500px) !important;
    }
    //Pushed state
    &.is-active {
        transform: translateX(-5px);
    }
    .inner {
        position: relative;
        height: 100%;
        //header
        .quickview-header {
            height: 80px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid rgba(0,0,0,0.1);
            span {
                cursor: pointer;
            }
            h2 {
                position: relative;
                top: -4px;
                font-size: 1.15rem;
                font-family: 'Raleway', sans-serif;
                font-weight: 500;
                color: $dark-text;
                text-transform: uppercase;
                letter-spacing: 6px;
                padding-left: 20px;
            }
            svg {
                margin: 0 20px;
                width: 20px;
                height: 20px;
                stroke: $dark-text;
                &:hover {
                    stroke: $primary;
                }
            }
        }
        //body
        .filters-body {
            position: relative;
            overflow-x: hidden;
            overflow-y: auto;
            max-height: 100vh;
            padding: 30px 20px;
            &::-webkit-scrollbar {
                width: 10px;
            }

            &::-webkit-scrollbar-thumb {
                border-radius: 5px;
                background: rgba(0,0,0,0.2);
            }
        }
        //Filter block element
        .filter-block {
            width: 100%;
            margin-bottom: 30px;
            .filter-title {
                position: relative;
                font-size: 0.8rem;
                font-weight: 500;
                color: $dark-text;
                text-transform: uppercase;
                letter-spacing: 1px;
                &.has-padding {
                    padding: 2px 0;
                }
            }
        }
        .checkbox-wrap {
            padding: 0;
            color: $dark-text !important;
            font-family: 'Raleway', sans-serif;
            font-size: .9rem;
            span {
                position: relative;
                top: -2px;
            }
        }
    }
}

/* ==========================================================================
4. Cart Sidebar
========================================================================== */

.cart-quickview {
    position: fixed;
    top: 0;
    left: 84px;
    height: 100%;
    width: 340px;
    background-color: $white;
    background-size: cover;
    background-position: bottom;
    box-shadow: 2px 0px 2px 0px rgba(0,0,0,0.02);
    z-index: 3;
    transform: translateX(-345px);
    transition: all .3s;
    //Mobile pushed state
    &.is-pushed-mobile {
        transform: translateX(-500px) !important;
    }
    //Pushed state
    &.is-active {
        transform: translateX(-5px);
    }
    .inner {
        position: relative;
        height: 100%;
        //header
        .quickview-header {
            height: 84px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid rgba(0,0,0,0.1);
            span {
                cursor: pointer;
            }
            h2 {
                position: relative;
                top: -4px;
                font-family: 'Raleway', sans-serif;
                font-size: 1rem;
                font-weight: 500;
                color: $dark-text;
                text-transform: uppercase;
                letter-spacing: 6px;
                padding: 0 20px;
            }
            svg {
                margin: 0 20px;
                width: 20px;
                height: 20px;
                stroke: $dark-text;
                &:hover {
                    stroke: $primary;
                }
            }
        }
        //Total price and checkout link
        .cart-action {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 20px;
            height: 80px;
            background: #fcfcfc;
            border-bottom: 1px solid $fade-grey;
            .cart-total {
                display: block;
                font-size: 1.7rem;
                color: $primary;
                font-weight: 600;
                small {
                    font-size: 70%;
                    font-weight: 600;
                    padding-right: 5px;
                }
                .abstract {
                    display: block;
                    font-size: 0.8rem;
                    color: $muted-grey;
                    span {
                        font-size: 1rem;
                        font-weight: 600;
                    }
                }
            }
            a {
                min-width: 130px;
                font-weight: 200;
                font-size: 80%;
                height: 40px;
                svg {
                    position: relative;
                    top: 1px;
                    width: 17px;
                    height: 17px;
                    margin-right: 8px;
                }
            }
        }
        //body
        .cart-body {
            //Empty Cart
            .empty-cart {
                h3, img {
                    display: block;
                }
                h3 {
                    font-family: 'Raleway', sans-serif;
                    font-size: 1.2rem;
                    font-weight: 500;
                    padding: 20px 0;
                    color: $muted-grey;
                }
                //Image
                img {
                    max-height: 90px;
                    max-width: 90px;
                    margin: 0 auto;
                }
                a {
                    margin-top: 20px;
                    color: $primary;
                    border: 1.4px solid $primary;
                    transition: all .4s;
                    &:hover {
                        //opacity: 0.4;
                        color: rgba(0, 35, 255, 0.4);
                        border-color: rgba(0, 35, 255, 0.4);
                    }
                }
                small {
                    display: block;
                    color: $muted-grey;
                    font-size: 80%;
                    margin-top: 10px;
                }
            }
            //items list
            .shopping-cart-items {
                position: relative;
                overflow-x: hidden;
                overflow-y: auto;
                max-height: 80vh;
                min-height: 60vh;
                &::-webkit-scrollbar {
                    width: 10px;
                }

                &::-webkit-scrollbar-thumb {
                    border-radius: 5px;
                    background: rgba(0,0,0,0.2);
                }
                li {
                    height: 80px;
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    padding: 0 20px;
                    position: relative;
                    border-bottom: 1px solid rgba(0,0,0,0.1);
                    &:hover {
                        .remove-item {
                            opacity: 1;
                            transform: translateX(0);
                        }
                    }
                }
                //item photo
                img {
                    float: left;
                    max-height: 50px;
                }
                .item-meta {
                    display: block;
                    width: 40%;
                    padding: 0 10px;
                }
                //Name
                .item-name {
                    display: block;
                    font-size: 13px;
                    font-weight: 500;
                    text-transform: uppercase;
                }
                //Price
                .item-price {
                    color: $primary;
                    margin-right: 8px;
                }
                //Quantity
                .quantity {
                    display: block;
                    margin: 0 10px;
                    .input {
                        max-width: 70px;
                        margin: 0 10px;
                        text-align: center;
                        height: 32px;
                    }
                }
                .item-quantity {
                    color: $muted-grey;
                    small {
                        font-size: 80%;
                    }
                }

                //remove item
                .remove-item {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 40px;
                    height: 78px;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: all .45s;
                    opacity: 0;
                    transform: translateX(200px);
                    background: #f9f9f9;
                    svg {
                        width: 18px;
                        height: 18px;
                        stroke: $muted-grey;

                    }
                    &:hover {
                        background: #fcfcfc;
                        svg {
                            stroke: $primary;
                        }
                    }
                }
            }
        }
    }
}

/* ==========================================================================
5. Sidebar Header
========================================================================== */

.quickview-header {
    span {
        &:hover svg {
            transform: rotate(180deg);
        }
    }
    svg {
        transform: rotate(0);
        transition: all .3s;
    }
}