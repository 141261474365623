/*! _buttons.scss | Nephos | CSS Ninja */

/* ==========================================================================
Button styles
========================================================================== */

/***
TABLE OF CONTENTS
=============================================================================
0. Basic buttons
1. Colored buttons
=============================================================================
***/

/* ==========================================================================
0. Basic buttons
========================================================================== */

.button {
    //make the button bigger
    &.big-button {
        padding: 22px 40px !important;
        //bold and outlined
        &.btn-outlined.is-bold {
            border-width: 2px;
            font-weight: 600;
        }
        &.is-bold {
            font-weight: 600;
        }
    }
    //Hover raised buttons
    &.raised:hover {
        box-shadow: 0 14px 26px -12px rgba(0, 0, 0, 0.42), 0 4px 23px 0px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2) !important;
        opacity: 0.8;
    }
    //Uppercase button
    &.upper-button {
        text-transform: uppercase;
        &.is-bold {
            font-weight: 600 !important;
        }
    }
    //Rounded buttons
    &.rounded {
        border-radius: 500px;
    }
    //Helios CTA button
    &.feather-button {
        min-width: 130px;
        font-weight: 200;
        font-size: 80%;
        height: 40px;
        &.is-pushed {
            margin-left: auto;
        }
        svg {
            position: relative;
            top: 1px;
            width: 17px;
            height: 17px;
            margin-right: 8px;
        }
        //size modifier
        &.is-small {
            min-width: 100px;
            height: 32px;
            font-size: 60%;
            font-weight: 500;
        }
        //Bold weight
        &.is-bold {
            font-weight: 500 !important;
            font-size: .75rem;
            text-transform: uppercase;
            border-width: 2px;
        }
    }
}

button[type=submit], .will-upload {
    display: block !important;
    text-align: center !important;
}

/* ==========================================================================
1. Colored Buttons
========================================================================== */

//primary button
.button.primary-button {
    outline: none;
    border-color: $primary;
    background-color: $primary;
    color: $white !important;
    transition: all 0.5s;
    &:hover {
        color: $white;
    }
    //raised state
    &.raised:hover {
        box-shadow: $primary-box-shadow;
        opacity: 0.9.5;
    }
    //outlined button
    &.btn-outlined {
        border-color: $primary;
        color: $primary !important;
        background-color: transparent; 
        &:hover {
            color: $white !important;
            background-color: $primary;
        }
    }
    &:focus {
        border-color: $primary;
    }
}

//secondary button
.button.secondary-button {
    outline: none;
    border-color: $secondary;
    background-color: $secondary;
    color: $white;
    transition: all 0.5s;
    &:hover {
        color: $white;
    }
    //raised state
    &.raised:hover {
        box-shadow: $secondary-box-shadow;
        opacity: 0.95;
    }
    //outlined button
    &.btn-outlined {
        border-color: $secondary;
        color: $secondary;
        background-color: transparent; 
        &:hover {
            color: $white;
            background-color: $secondary;
        }
    }
    &:focus {
        border-color: $secondary;
    }
}

//accent button
.button.accent-button {
    outline: none;
    border-color: $accent;
    background-color: $accent;
    color: $white;
    transition: all 0.5s;
    &:hover {
        color: $white;
    }
    //raised state
    &.raised:hover {
        box-shadow: $accent-box-shadow;
        opacity: 0.8;
    }
    //outlined button
    &.btn-outlined {
        border-color: $accent;
        color: $accent;
        background-color: transparent;
        &:hover {
            color: $white;
            background-color: $accent;
        }
    }
    &:focus {
        border-color: $accent;
    }
}

//Grey button
.button.grey-button {
    outline: none;
    border-color: $accent-grey;
    background-color: $accent-grey;
    color: $white;
    transition: all 0.5s;
    &:hover {
        color: $white;
    }
    //raised state
    &.raised:hover {
        opacity: 0.8;
    }
    //outlined button
    &.btn-outlined {
        border-color: $accent-grey;
        color: $accent-grey;
        background-color: transparent;
        &:hover {
            color: $white;
            background-color: $accent-grey;
        }
    }
    &:focus {
        border-color: $accent-grey;
    }
}