﻿$bfs-tile-icon-size: 100px;
.flat-card.bfs-tile-card {

    display: flex;
    flex-direction: column;

    min-height: 100%;
    max-height: unset;

    @include mobile {
        max-height: unset;
    }

    .card-header {
        display: flex;
        justify-content: space-between;
        box-shadow: none;
        border-bottom: 1px solid $fade-grey;

        .bfs-tile-header-title {
            padding: .8em 1em;
        }

        .bfs-tile-header-option{
            padding: 0 1em;
            display: flex;
            align-items: center;

            a {
                height: 32px;

                .navigation-arrow {
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }

    .card-body {
        flex-grow: 1;
        padding: 1em;

        display: flex;
        flex-direction: row;

        font-size: 13px;

        .bfs-tile-icon-container {
            flex-grow: 0;
            flex-shrink: 0;
            margin-left: 1em;
            margin-right: 2em;
            position: relative;

            align-self: center;
            justify-content: center;

            @include mobile {
                flex-grow: 1;
                display: flex;
                justify-content: center;
            }

            .dashboard-icon-addon {
                $dashboard-icon-addon-size: 3rem;

                font-size: $dashboard-icon-addon-size * .66;

                width: $dashboard-icon-addon-size;
                height: $dashboard-icon-addon-size;
                background-color: white;

                position: absolute;
                top: calc(#{$bfs-tile-icon-size} - #{$dashboard-icon-addon-size * .75});
                left: calc(#{$bfs-tile-icon-size} - #{$dashboard-icon-addon-size * .75});
                border-radius: 1000px;

                display: flex;
                align-items: center;
                justify-content: center;
            }

            .dashboard-icon {
                width: $bfs-tile-icon-size;
                height: $bfs-tile-icon-size;
            }

            .dashboard-icon-medium {
                width: $bfs-tile-icon-size * .75;
                height: $bfs-tile-icon-size * .75;
            }

            .dashboard-icon-small {
                width: $bfs-tile-icon-size * .5;
                height: $bfs-tile-icon-size * .5;
            }
        }

        .bfs-tile-content {
            flex-shrink: 1;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .large {
                font-size: 18px;
                font-weight: bold;
            }

            .emphasized {
                font-size: 15px;
                font-weight: bold;
            }

            .subtle {
                color: #9e9e9e;
            }
        }
    }

    .card-footer {
        display: flex;
        justify-content: end;
        padding: 0 1em .5em;
        border: 0;
        min-height: 2.5em;

        .notification-box {
            display: flex;
            align-items: center;
            height: 100%;

            .switch[type=checkbox] + label {
                padding-top: 0;
            }
        }
    }
}

.notification-tile {
    background-color: #545454;
    color: white;

    border-radius: $card-radius;
    border: 0;

    padding: 1rem;

    font-size: 15px;

    a:hover {
        color: white;
    }

    .flat-card.bfs-tile-card {
        background-color: transparent;
        border: 0;

        .card-header {
            border: 0;

            .notification-title {
                display: flex;
                align-items: center;

                .notification-flag {
                    border-radius: 3px;
                    background-color: #2477e1;
                    padding: 0.2em 0.4em;
                    margin-right: 10px;

                    font-weight: bold;
                    text-transform: capitalize;
                }

                .notification-title-text {
                    font-weight: bold;
                    font-size: 20px;
                }
            }
        }

        .card-body {
            .vertrags-buttons {
                display: flex;
                align-items: flex-end;

                &>*:not(:last-child) {
                    margin-right: 1rem;
                }
            }
        }
    }
}
