﻿.verrechnungen-container .verrechnungen {
    &.box {
        background-color: $darkgraybackground;

        p {
            color: white;
        }

        .column {
            display: flex;
            flex-direction: row;

            img {
                width: 50px;
                height: 50px;
                margin-right: 10px;

                @include from($desktop){
                    width: 130px;
                    height: 130px;
                    margin-right: 0px;
                }
            }
        }
    }

    .flat-card {
        margin-bottom: 0;

        .card-title {
            flex-direction: row;
            align-items: center;

            @include mobile{
                flex-wrap: wrap;

                &>*{
                    padding: .5rem 0;
                }
            }

            h3 {
                margin-right: 10px;
            }

            .headerbuttons {
                display: flex;
                flex-direction: row;

                align-self: flex-end;

                &>*:not(:last-child) {
                    margin-right: 1rem;
                }
            }
        }
    }

    .dta-file {
        display: flex;
    }

    .tag {
        margin-left: 10px;
    }
}


    table.tickets {
        tbody {
            .ticket-betreff {
                width: 100%;
            }

            a.go-to-ticket {
                width: 32px;
                display: block;
            }
        }
    }

    .ticket-list {
        .ticket {
            margin-bottom: 1rem;

            .go-to-ticket {
                margin-left: 1.5rem;
            }
        }
    }
