// FONTS
@font-face {
  font-family: 'klavika_regular';
  src: url('public/fonts/klavika/klavika_regular-webfont.eot');
  src: url('public/fonts/klavika/klavika_regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('public/fonts/klavika/klavika_regular-webfont.woff2') format('woff2'),
       url('public/fonts/klavika/klavika_regular-webfont.woff') format('woff'),
       url('public/fonts/klavika/klavika_regular-webfont.ttf') format('truetype'),
       url('public/fonts/klavika/klavika_regular-webfont.svg#klavika_rgregular') format('svg');
  font-weight: normal;
  font-style: normal;

}

/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: local('Raleway Light'), local('Raleway-Light'), url('public/fonts/Raleway/1Ptrg8zYS_SKggPNwIYqWqhPAMif.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 300;
  src: local('Raleway Light'), local('Raleway-Light'), url('public/fonts/Raleway/1Ptrg8zYS_SKggPNwIYqWqZPAA.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: local('Raleway'), local('Raleway-Regular'), url('public/fonts/Raleway/1Ptug8zYS_SKggPNyCMIT5lu.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 400;
  src: local('Raleway'), local('Raleway-Regular'), url('public/fonts/Raleway/1Ptug8zYS_SKggPNyC0ITw.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: local('Raleway Medium'), local('Raleway-Medium'), url('public/fonts/Raleway/1Ptrg8zYS_SKggPNwN4rWqhPAMif.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Raleway';
  font-style: normal;
  font-weight: 500;
  src: local('Raleway Medium'), local('Raleway-Medium'), url('public/fonts/Raleway/1Ptrg8zYS_SKggPNwN4rWqZPAA.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}



/* latin-ext */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 100;
  src: local('Alegreya Sans SC Thin'), local('AlegreyaSansSC-Thin'), url('public/fonts/Alegreya_Sans_SC/mtGn4-RGJqfMvt7P8FUr0Q1j-Hf1DipV-wREDYs.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 100;
  src: local('Alegreya Sans SC Thin'), local('AlegreyaSansSC-Thin'), url('public/fonts/Alegreya_Sans_SC/mtGn4-RGJqfMvt7P8FUr0Q1j-Hf1DipV-wREDYs.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 400;
  src: local('Alegreya Sans SC Regular'), local('AlegreyaSansSC-Regular'), url('public/fonts/Alegreya_Sans_SC/mtGh4-RGJqfMvt7P8FUr0Q1j-Hf1Bkdl9xxA.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Alegreya Sans SC';
  font-style: normal;
  font-weight: 400;
  src: local('Alegreya Sans SC Regular'), local('AlegreyaSansSC-Regular'), url('public/fonts/Alegreya_Sans_SC/mtGh4-RGJqfMvt7P8FUr0Q1j-Hf1Bkll9w.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}



/* latin-ext */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url('public/fonts/Open_Sans/mem8YaGs126MiZpBA-UFW50bbck.woff2') format('woff2');
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'), url('public/fonts/Open_Sans/mem8YaGs126MiZpBA-UFW50bbck.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}


// Fontawesome

$fa-font-path:        "public/fonts/fontawesome-pro-5.15.4-web/webfonts";

@import "./fontawesome-pro-5.15.4-web/fontawesome.scss";
@import "./fontawesome-pro-5.15.4-web/solid.scss";
@import "./fontawesome-pro-5.15.4-web/light.scss";
