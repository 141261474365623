﻿.dropdown.is-fullwidth {
    display: flex;

    .dropdown-trigger {
        width: 100%;
        button {
            width: 100%;
            display: flex;
            justify-content: space-between;

            // Multiline
            height: auto;
            min-height: 2.5rem;

            span {
                white-space: break-spaces;
                text-align: left;
            }
        }
    }
    .dropdown-menu {
        width: 100%;

        .dropdown-item {
            white-space: break-spaces;

            &.is-active {
                background: $bfsorange;
            }
        }
    }
}
