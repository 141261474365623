﻿.kontakt-grid {

    display: grid;
    align-items: stretch;
    justify-content: stretch;
    gap: 1em;

    @include mobile {
        grid-template-columns: 100%;
        grid-template-areas:
            "ticket-card"
            "chat-card"
            "email-card"
            "telefon-card";
    }

    @mixin two-column-layout {
        grid-template-columns: 50% 50%;
        grid-template-areas:
            "ticket-card chat-card"
            "email-card telefon-card";
    }

    @include tablet {
        @include two-column-layout;
    }

    .ticket-card { grid-area: ticket-card; }
    .chat-card { grid-area: chat-card; }
    .email-card { grid-area: email-card; }
    .telefon-card { grid-area: telefon-card; }

    .flat-card.bfs-tile-card .card-body {
        flex-wrap: wrap;
        justify-content: space-evenly;

        .action {
            align-self: start;
        }

        .bfs-tile-icon-container {
            @include mobile {
                flex-grow: 0;
            }
        }

        .bfs-tile-content {
            width: 1px; // Das braucht eine Mindestbreite für flex-wrap
            min-width: 200px;
        }
    }
}
