﻿div.modal.video-overlay {
    .modal-content {

        width: 640px;
        height: 360px;
        max-height: unset;
        max-width: unset;

        line-height: 0;

        iframe {
            width: 100%;
            height: 100%;
        }

        // Eigentlich hätten wir das gern responsive. Allerdings handelt es sich bei aspect-ratio eher um sogenannte Richtlinien als um Regeln.
        // Deshalb definieren wir hier feste Breakpoints
        @media (max-width: 700px), (max-height: 400px) {
            width: 533px;
            height: 300px;
        }

        @media (max-width: 600px) {
            width: 460px;
            height: 259px;
        }

        @media (max-width: 500px) {
            width: 360px;
            height: 202px;
        }
    }
}
