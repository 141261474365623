@import 'portale-partials/template';
@import 'portale-partials/collapsable';
@import 'portale-partials/modal';
@import 'portale-partials/login';
@import 'portale-partials/list';
@import 'portale-partials/messagebox';
@import 'portale-partials/videoOverlay';
@import 'portale-partials/typography';

html {
  background: white;
}

.center-msg {
    justify-content: center;
    display: flex;
    margin: 20px;
}

.center-text {
  text-align: center;
}

.waiting {
    justify-content: center;
    display: flex;
    margin-top: 25px;
    margin-bottom: 25px;
}

.pull-right {
	text-align: right !important;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.error-msg {
    color: $red;
}

.dropdown-content .dropdown-item {
    text-align: left;
}

.hero-body,
.section {
    padding: 3rem 1.5rem;
}

.tag:not(body).is-small {
    font-size: 0.75rem;
    padding-left: 0.25em;
    padding-right: 0.25em;
    height: unset;

    a {
        color: $white;
        &:hover {
            color: black;
            fill: black;
        }
    }
  }

  .taglist {
    display: flex;
    flex-wrap: wrap;

    .tags {
        margin-right: 0.2em;
    }

    :last-child {
        margin-bottom: 0.2em;
    }
    :not(:last-child)    {
        margin-bottom: 0.2em;
    }
}


  .modal-card-head.is-danger {
        background: $danger;
  }



  .modal-card-foot {
    justify-content: flex-end;

    &.is-left {
        justify-content: flex-start;
    }
  }

  .modal-background {
    background-color: rgba(10, 10, 10, 0.75);
  }

  .modal {
    z-index: 200;
  }

  .download-icon {
    font-size: 1.5em;
    margin-left: 5px;
    margin-right: 5px;

    color: $chosen-dark-grey;
  }

   .deaktiviert {
       text-decoration: line-through;

       a {
          display: inline-block; /* Avoid text-decoration propagation from ul */
       }


   }

  .icon {
    font-size: 1em;
    margin-left: 5px;
    margin-right: 5px;

    color: $chosen-dark-grey;
  }

  .linkicon {
    @extend .icon;

    color: $bfsorange;

    i {
      transition: all 1s, color 0s;

      &.upsidedown {
        transform: rotate(180deg);
      }
    }

  }

  a:hover .linkicon{
      color:#363636;
      fill:#363636;
  }

  .videolink {
    margin: 15px;
  }

  .file-icon {
    font-size: 1.0em;
    margin-top: 4px;
    margin-left: 5px;
    margin-right: 5px;

    color: $chosen-dark-grey;
  }

  .small-icon {
    font-size: 0.9em;
    margin-left: 5px;
    margin-right: 5px;

    color: $chosen-dark-grey;
  }

  .category-header-faq {
    @extend .category-header;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      justify-content: unset;
      align-items: unset;
      margin-bottom: 16px;

      .field {
        align-self: flex-end;
      }
    }
  }

  .faq-card-content {
    overflow: hidden;
  }

  .klickbot-card-content{
    overflow: hidden;

    &.answers {
      display: flex;
      flex-direction: column;
      .content {
        margin-bottom: 0.75rem;
      }
    }

    &.byebye {
      .content {
        flex-direction: column;
        margin-left: 2rem;
      }
    }

    .content {
      display: flex;
      flex-direction: row;

      margin-left: 132px; // = .klickbot-avatar.width + 2* margin + x :shrug:

      @media screen and (max-width: 450px) {
        margin-left: 10px;
      }

      margin-right: 2rem;
    }
  }
  .klickbot-card-question {
    .card-header-title {
      margin-top: 28px; // so viel, so dass es aussieht als würde es dem bot aus dem mund kommen
      align-items: unset;
    }

    &.endantwort {
      @media screen and (max-width: 450px) {
        flex-wrap: wrap;

        .card-header-title {
          padding: 0;
          margin-left: 2rem;
          margin-right: 2rem;
          margin-bottom: 25px;
          margin-top: 0px;
        }
      }
    }
  }

  .klickbot-avatar {
    flex: none;
    width: 100px;
    margin: 10px;
}

  .titel {
      .tooltip {
        // nicht wie im Title itself 600 = fett
        font-weight: 400;
      }
  }

  .box {
    z-index: 39; // genutzt vom Datepicker, damit er weit vorne ist
  }

.section {
  padding: 0rem 1.5rem 3rem 1.5rem;
}


.calendar {
  .calendar-nav {
    background: $chosen-dark-grey;
  }

   .button.is-link {
      background: gray;
  }
}

.paragraphs {
  p {
    margin-bottom: 0.8em;
  }

  .bigger {
    font-family: 'Raleway', sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
   }

   &.disclaimer {
    margin-bottom: 0px;
    margin-top: 4rem;
    color: #999;
    font-size: 0.75rem;
   }
}

.small-paragraphs {
  p {
    margin-bottom: 0.2em;
  }
}

.card-with-tooltip {
  overflow: visible;
}

.nowrap {
  white-space: nowrap;
}

.pre {
    white-space: pre;
}

// erlaubt newlines im multiline tooltip
.tooltip.is-tooltip-multiline::before { white-space:pre-line }

.files-list {
  margin-bottom: 1em;
}

.admin-klickbot {
  .klickbottype-part {
    display: flex;

    .tab {
      flex: 2;
    }
    .upload {
      flex: 1;
      margin: 0px 25px
    }
  }
}

.message-body {
  .button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
      flex-wrap: wrap;

      @include mobile {
          align-items: stretch;
          &>* {
              flex-grow: 1;
          }
      }

	.button {
      margin-left: 10px;
        margin-top: 10px;
	}
   }
   white-space: pre-line;

   .content {
     p {
       margin-top: 0;
       margin-bottom: 0;
     }

     h1, h2, h3, h4, h5, h6 {
       margin-top: 1rem;
       margin-bottom: 0;
     }
     ul {
       margin-block-start: 0;
       margin-block-end: 0;
       margin-bottom: 0;
       margin-top: 0;
     }
   }
}

.is-danger {
  .message-body {
    background-color: $mainbackground;
  }
}

.is-bfscolored {

    &.message {

        .message-header {
            background-color: $bfsorange;
            color: white;
        }

        .button {
            background-color: $bfsorange;
            border-color: transparent;
            color: white;
        }

        .message-body {
            background-color: $mainbackground;
        }
    }
}

.dropoverlay {
  visibility: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  background:darkgray;
  color: white;
  padding-top: 2em;
  text-align: center;
  z-index: 10;
  opacity: .9;

  p {
    pointer-events: none;
  }
}
.dragging{
  visibility: visible;
}

.help-center {
  .flat-card {
      height: 100% !important;
  }

  // a.help-head {
  //     color: $linkblau;
  // }
}

.in-top-right-corner {
  position: absolute;
  right: 20px;
  top: 20px;
}
label.radio {
    input {
      margin-right: 5px;
      margin-left:  25px;
    }
}

.with-overflowing-tooltips {
  overflow: visible;
}
.menu-card {
  li.wishlist-item {
    border-left: 10px solid transparent;

    a {
      color: $bfsorange;
    }

    &.new-kategorie {
      border-left: 10px solid $bfsorange;
    }

    &.is-active {
      background-color: $darkgraybackground;
      color: white;

      a {
        color: white !important;
      }
    }

    @media(hover: hover) and (pointer: fine) {
      &:hover, &.is-active:hover {
        background-color: $bfsorange;

        color: white;

        a {
          color: white !important;
        }
      }
    }
  }
}

.orangeCheckbox {
  fill: $dark-text;
  cursor: pointer;

  &.is-active {
    fill: $bfsorange;
  }

  &.is-disabeld {
    fill: lightgray;
    cursor: not-allowed;

    &:hover {
      fill: lightgray;
    }
  }

  height: 100%;

  @media(hover: hover) and (pointer: fine) {
    &:hover {
      fill: $bfslightorange;
    }
  }
}

.bfsbutton {
  @extend .is-link;
  @extend .button;

  color: white;
  background-color: $bfsorange;
  border-color:  $bfsorange;

  .icon {
      color: white;
  }

  &.gray {
    background-color: lightgray;
    border-color:  lightgray;
    color: black;

    &[disabled],
    &.is-loading {
      background-color: gray;
      border-color:  gray;
      color: white;
    }
    &:hover {
      background-color: lightgray;
      border-color:  lightgray;
      color: black;

      @media (hover: hover) and (pointer: fine) {
        background-color: gray;
        border-color:  gray;
        color: white;
      }
    }
  }

  &[disabled],
  &.is-loading  {
    background-color: $bfslightorange;
    border-color:  $bfslightorange;
  }
  &:hover {
    background-color: $bfsorange;
    border-color:  $bfsorange;

    @media (hover: hover) and (pointer: fine) {
        background-color: $bfslightorange;
        border-color:  $bfslightorange;
    }
  }


  &.wide {
    width: 100%;
  }
}

.button.is-bfsorange {
    &:focus:not(:active){
        box-shadow: none;
    }

    &:hover {
        @media (hover: hover) and (pointer: fine) {
            background-color: $bfslightorange;
            border-color:  $bfslightorange;
        }
    }
}

.bfsdelete {
  @extend .delete;
  @extend .is-large;

  background-color: transparent;

  position: absolute;
  right: 10px;
  top: 10px;

  &.narrow {
    right: 3px;
    top: 3px;
  }

  &::before  {
    height: 1px;
    background-color: $bfsorange;
  }
  &::after  {
    width: 1px;
    background-color: $bfsorange;
  }

  &:focus, &:hover {
    background-color: lightgrey;
  }
}

// Forms / Controls
div.control {
  border: 0px;

  &:focus {
    border: 0px;
  }
}

.select:not(.is-multiple):not(.is-loading)::after {
    // der Pfeil nach unten rechts
    border-color: $graytext;
}
.select {
  border: 0px;

  select {
    &:focus {
      border: 1px solid $bfslightorange;
      box-shadow: unset;
    }
  }
  &.wide {
    width: 100%;
  }
}

input.input,
textarea.textarea {
  &:focus {
    border-color: $bfslightorange;
    box-shadow: unset;
  }
}

.field {
  label {
    color: $muted-grey;
    font-weight: 400;
    font-size: 0.8em;
  }
}

// HOME etc
.homepage {
  margin-top: 15px;

  .view-more {
    text-align: right;
    padding-right: 40px;
  }
}

.welcomebox {
  padding: 0px;
  margin-bottom: 0.9em;

  // TODO kann vielleicht weg
  .content {
    margin: 20px 40px;

    h1 {color: $dark-text;}
    h2 {color: $dark-text;}
    h3 {color: $dark-text;}
    h4 {color: $dark-text;}
    h5 {color: $dark-text;}
    h6 {color: $dark-text;}
  }
}

// Userlike
#userlike {
  display: none;
}

#userlike.showchat {
  display: initial;
}

// User Management
.usermanagement {
 .new-button-contailer {
   display: flex;
   flex-direction: row-reverse;
 }
}

// Ticketsystem
.ticketsystem {
    tr.has-additional-data-row {

        cursor: pointer;

        td {
            border: 0 !important;
        }

        td.ticket-title .collapsable {
            min-height: 1.5em; // weil in der Tabelle lineheight 1,5 ist
        }
    }

    tr.additional-data {
        td {
            padding-top: 0;
            padding-bottom: 0;

            .ticket-body {
                padding-bottom: 0.5em;
            }
        }
    }

    .upload-box .card-body .file-cta,
    .align-with-textarea {
        min-height: 13em;
    }

    textarea.is-big {
        min-height: 13em;
    }

    .file-label {
        width: 100%;
    }
}

// news
.newslist {
  &.is-product-list {
    .flat-card {
      margin-top: 0px;
    }

    a {
      i {
        margin-left: 5px;
      }
    }
  }
}

.upload-box {

    background-color: $darkgraybackground;
    color: white;

    .file-label {

        flex-grow: 1;

        .file-cta {
            padding: 1em;
            background-color: $darkgraybackground;
            color: white;

            border-radius: unset;
            border-style: dashed;

            .file-icon {
                color: white;
                height: auto;
            }

            .file-label {
                padding-top: 1.5em;

                text-align: center;
                align-items: unset;
                justify-content: unset;
                position: unset;

                white-space: pre-wrap;
            }
        }
    }

    .file-list {
        margin-top: 1em;
        margin-bottom: 1em;

        line-height: 2;

        .file-list-item {
            display: flex;

            .file-name-left {
                flex-grow: 1;
            }

        }
    }

    a {
        line-height: 2;
        color: white;
        margin-left: 5px;
    }
}

.failed-upload-list {
    margin: .5rem 1rem;
    list-style: '- ';
}

// Dokumnente
.dokumente {
  tr {
    transform: scale(1); // Workaround damit position absolute für New Indicator geht

      td {
          position: relative;

          .new-item-wrapper {
              width: 10px;
              left: -20px; // gleicht den Margin der card aus
          }
      }
  }
}

.passwordreset {
  &.loginform {
    h2 {
      font-size: 1.8rem;
    }
    p {
      font-size: 1rem;
    }
  }
}

.card-title-with-tabs {
  font-weight: bold;
  background: #fcfcfc;
  margin-top: 8px;

  li {
    margin-left: 5px;
    a{
      color: $muted-grey-bold;
    }
  }
}

// Timeline / Quickfinder
.timeline {
  span.tag  {
    background-color: #dbdbdb;
    color: $chosen-dark-grey;
    font-weight: 600;
  }

  .timeline-marker {
    text-align: center;
    margin-top: 1.1em; // so weit wie das date unten = 0.8em *1.5 und dann ein tick weniger

    i {
      margin-top:  7px;
      color: $chosen-dark-grey;

    }
  }

  // header:first-child {
  //     display: block;
  // }


  .timeline-item {
    &:last-child::before { // Linie vom letzten item bis zum End-Element
      height: 50px;
    }

    .timeline-content {
      .date {
        font-weight: 600;
        display: block;
        font-size: 0.8em;
        margin-bottom: 5px;
        line-height: 1.5;
      }

      .helptext {
        margin-top: 5px;
        color: $graytext;

        p {
          margin-bottom: 8px;
        }
      }
    }
  }
}

// Kontkt
.kontaktpage {
  .link {
    text-align: center;
  }
}

// Quickfinder
.quickfinder-box {

    .flat-card {
      background-color: $darkgraybackground;
      color: white;
    }

    .quickfinderImg {
      width: 87px;
      margin-right: 20px;
    }
    .quickfinderImgSmall {
      height: 50px;
      margin-right: 10px;
      margin-top: 10px;
    }

    .flat-card {
      padding: 20px;
      .card-body {
        align-items: center;
      }
    }

    .firstcol {
      display: flex;
      align-items: center;

        &>*:last-child {
            flex-grow: 1;
        }
    }

    .field {
      justify-content: flex-end;
    }

    .firstcol a {
      &:hover {
        color: lightgray;
      }
    }
}

.quickfinder {
  margin-left: 15px;

  .is-32x32 {
    div {
      // dann passt das icon gut in den 32x32 px kreis
      width: 18px;
      height: 18px;
    }
  }

  .quicksort {
    position: absolute;
    right: 15px;
  }

  .icon-achtung     { margin-top: 5px; margin-left: 5px; background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><defs><style>.cls-1{fill:%2366676b;}</style></defs><path class='cls-1' d='M29.22,25.55,16.55,2.77a1.79,1.79,0,0,0-1.55-1h0a1.8,1.8,0,0,0-1.55,1L.78,25.55A1.86,1.86,0,0,0,.72,27.4a1.84,1.84,0,0,0,1.65.85H27.63a1.73,1.73,0,0,0,1.59-2.7Zm-26.54.69L15,4.11,27.31,26.25Z'/><path class='cls-1' d='M14,11.86v6.81a1,1,0,0,0,2,0V11.86a1,1,0,1,0-2,0Z'/><circle class='cls-1' cx='15.01' cy='22.1' r='1.44'/></svg>"); }
  .icon-sanduhr     { margin: 6px; background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><defs><style>.cls-1{fill:%2366676b;}</style></defs><path class='cls-1' d='M17.65,15c2.92-1.57,7.45-5.28,7.45-13.2a3.15,3.15,0,0,0,0-.37,1,1,0,0,0-1-.93H5.93a1.11,1.11,0,0,0-1,1,3,3,0,0,0,0,.31c0,7.92,4.53,11.63,7.45,13.2C9.43,16.57,4.9,20.28,4.9,28.2a3.15,3.15,0,0,0,0,.37,1,1,0,0,0,1,.93H24.07a1.08,1.08,0,0,0,1-1c0-.1,0-.2,0-.3C25.1,20.28,20.57,16.57,17.65,15ZM6.93,2.5H23.08c-.32,8.47-6.5,11-8,11.47h-.16C13.42,13.48,7.29,11,6.93,2.5Zm0,25c.32-8.47,6.5-11,8-11.47h.16c1.5.49,7.64,3,8,11.47Z'/><path class='cls-1' d='M15,8.83a1.13,1.13,0,1,0-1.12-1.12A1.13,1.13,0,0,0,15,8.83Z'/><path class='cls-1' d='M13,9.35a1.13,1.13,0,1,0,1.12,1.13A1.13,1.13,0,0,0,13,9.35Z'/><path class='cls-1' d='M17,11.6a1.13,1.13,0,1,0-1.12-1.12A1.13,1.13,0,0,0,17,11.6Z'/><path class='cls-1' d='M19.06,8.83a1.13,1.13,0,1,0-1.12-1.12A1.13,1.13,0,0,0,19.06,8.83Z'/><path class='cls-1' d='M10.94,6.58a1.13,1.13,0,1,0,1.13,1.13A1.13,1.13,0,0,0,10.94,6.58Z'/><path class='cls-1' d='M15,17.63a1.13,1.13,0,1,0,1.13,1.13A1.13,1.13,0,0,0,15,17.63Z'/><path class='cls-1' d='M15,21.16a1.13,1.13,0,1,0,1.13,1.13A1.13,1.13,0,0,0,15,21.16Z'/></svg>"); }
  .icon-eingang     { margin-top: 5px; margin-left: 6px;  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><defs><style>.cls-1{fill:%2366676b;}</style></defs><path class='cls-1' d='M29.5,6.26a.53.53,0,0,0,0-.12.48.48,0,0,0,0-.2,1.42,1.42,0,0,0-.08-.18s0-.07-.06-.11,0,0-.05,0a1,1,0,0,0-.15-.14l-.15-.1-.18-.06-.2,0H1.43l-.2,0a.85.85,0,0,0-.17.06l-.15.1-.16.14s0,0-.05,0a1.17,1.17,0,0,1,0,.11l-.09.18a1.42,1.42,0,0,0,0,.2s0,.08,0,.12V23.74a1,1,0,0,0,1,1h27a1,1,0,0,0,1-1Zm-4,1L15,15.24l-10.53-8ZM2.5,22.74V8.27l11.9,9a1,1,0,0,0,1.2,0l11.9-9V22.74Z'/></svg>"); }
  .icon-kasse-minus { margin: 6px; background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><defs><style>.cls-1{fill:%2366676b;}</style></defs><path class='cls-1' d='M4.39,21.54V4.5a2,2,0,0,1,2-2H17.06a2,2,0,0,1,2,2v8.3h2V4.5a4,4,0,0,0-4-4H6.39a4,4,0,0,0-4,4v17a4,4,0,0,0,4,4h4.27v-2H6.39A2,2,0,0,1,4.39,21.54Z'/><path class='cls-1' d='M20.2,14.73a7.39,7.39,0,1,0,7.4,7.39A7.4,7.4,0,0,0,20.2,14.73Zm0,12.77a5.39,5.39,0,1,1,5.4-5.38A5.41,5.41,0,0,1,20.2,27.5Z'/><path class='cls-1' d='M17.42,8.54V6a1.75,1.75,0,0,0-1.75-1.75H7.78A1.75,1.75,0,0,0,6,6V8.54a1.75,1.75,0,0,0,1.76,1.75h7.89A1.75,1.75,0,0,0,17.42,8.54Zm-1.75,0H7.77V6h7.9Z'/><path class='cls-1' d='M22.89,21.24H17.52a.88.88,0,1,0,0,1.75h5.37a.88.88,0,0,0,0-1.75Z'/><path class='cls-1' d='M9.76,11.92H7.89a.88.88,0,1,0,0,1.75H9.76a.88.88,0,0,0,0-1.75Z'/><path class='cls-1' d='M9.76,15H7.89a.88.88,0,1,0,0,1.75H9.76a.88.88,0,0,0,0-1.75Z'/><path class='cls-1' d='M9.76,18H7.89a.88.88,0,1,0,0,1.75H9.76a.88.88,0,0,0,0-1.75Z'/><path class='cls-1' d='M13.69,11.92a.88.88,0,1,0,0,1.75h1.87a.88.88,0,0,0,0-1.75Z'/></svg>"); }
  .icon-kasse-plus  { margin: 6px; background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><defs><style>.cls-1{fill:%2366676b;}</style></defs><path class='cls-1' d='M4.39,21.54V4.5a2,2,0,0,1,2-2H17.06a2,2,0,0,1,2,2v8.3h2V4.5a4,4,0,0,0-4-4H6.39a4,4,0,0,0-4,4v17a4,4,0,0,0,4,4h4.28v-2H6.39A2,2,0,0,1,4.39,21.54Z'/><path class='cls-1' d='M20.21,14.73a7.39,7.39,0,1,0,7.4,7.39A7.4,7.4,0,0,0,20.21,14.73Zm0,12.77a5.39,5.39,0,1,1,5.4-5.38A5.4,5.4,0,0,1,20.21,27.5Z'/><path class='cls-1' d='M17.43,8.54V6a1.75,1.75,0,0,0-1.76-1.75H7.78A1.75,1.75,0,0,0,6,6V8.54a1.75,1.75,0,0,0,1.75,1.75h7.89A1.75,1.75,0,0,0,17.43,8.54Zm-1.76,0H7.78V6h7.9Z'/><path class='cls-1' d='M22.89,21.24H21.08V19.43a.88.88,0,0,0-1.75,0v1.81H17.52a.88.88,0,0,0,0,1.75h1.81V24.8a.88.88,0,1,0,1.75,0V23h1.81a.88.88,0,1,0,0-1.75Z'/><path class='cls-1' d='M9.76,11.92H7.89a.88.88,0,1,0,0,1.75H9.76a.88.88,0,0,0,0-1.75Z'/><path class='cls-1' d='M9.76,15H7.89a.88.88,0,1,0,0,1.75H9.76a.88.88,0,0,0,0-1.75Z'/><path class='cls-1' d='M9.76,18H7.89a.88.88,0,1,0,0,1.75H9.76a.88.88,0,0,0,0-1.75Z'/><path class='cls-1' d='M13.69,11.92a.88.88,0,1,0,0,1.75h1.87a.88.88,0,0,0,0-1.75Z'/></svg>"); }
  .icon-geld        { margin-top: 6px; margin-left: 4px; background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><defs><style>.cls-1{fill:%2366676b;}</style></defs><path class='cls-1' d='M23.61,27.5a13,13,0,0,1-12.42-8.83h6.34a1,1,0,0,0,0-2h-14a1,1,0,0,0,0,2H9.11A15,15,0,0,0,23.61,29.5a1,1,0,0,0,0-2Z'/><path class='cls-1' d='M3.53,14.48h14a1,1,0,0,0,0-2H10.88a12.87,12.87,0,0,1,12.73-10,1,1,0,0,0,0-2,14.82,14.82,0,0,0-14.75,12H3.53a1,1,0,0,0,0,2Z'/></svg>"); }
  .icon-hinweis     { margin-top: 5px; margin-left: 6px; background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><defs><style>.cls-1{fill:%2366676b;}</style></defs><path class='cls-1' d='M15,12.62a1,1,0,0,0-1,1v6.82a1,1,0,0,0,2,0V13.62A1,1,0,0,0,15,12.62Z'/><circle class='cls-1' cx='15' cy='9.39' r='1.32'/><path class='cls-1' d='M27.39,22.53a14.5,14.5,0,1,0-4.86,4.86l5.63,2.05a1,1,0,0,0,.34.06,1,1,0,0,0,.71-.29,1,1,0,0,0,.23-1.05Zm-4.64,2.82a1,1,0,0,0-.89.1,12.5,12.5,0,1,1,3.59-3.59,1,1,0,0,0-.11.89l1.49,4.08Z'/></svg>"); }
  .icon-aktion      { margin-top: 5px; margin-left: 6px; background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><defs><style>.cls-1{fill:%2366676b;}</style></defs><path class='cls-1' d='M23.23,9.79a2.89,2.89,0,0,0-.93.17,2.75,2.75,0,0,0-2.59-1.79,2.82,2.82,0,0,0-1.33.33,2.8,2.8,0,0,0-2.19-1.06,2.69,2.69,0,0,0-.76.12V3.08a2.76,2.76,0,0,0-5.52,0V14.89L8.79,13.8a3,3,0,0,0-4,.06,2.86,2.86,0,0,0-.16,3.75,26.17,26.17,0,0,1,1.78,3.5c1.79,3.91,3.81,8.34,6.9,8.43.81,0,1.92.06,3.06.06a23.6,23.6,0,0,0,3.72-.22c2.86-.51,5.92-2.75,5.92-7.46V12.49A2.74,2.74,0,0,0,23.23,9.79ZM24,21.92c0,4.26-3,5.26-4.28,5.5a42.79,42.79,0,0,1-6.43.12c-1.77-.05-3.76-4.39-5.07-7.26a26.85,26.85,0,0,0-2-3.83.94.94,0,0,1-.06-1.18,1,1,0,0,1,1.19,0L10.21,18a1,1,0,0,0,1.09.2,1,1,0,0,0,.61-.92V3.1a.75.75,0,0,1,.76-.7.73.73,0,0,1,.76.68v9.47a1,1,0,0,0,2,0V10.21h0v-.09a.73.73,0,0,1,.76-.67.75.75,0,0,1,.76.71v3.58a1,1,0,0,0,2,0V10.84a.73.73,0,0,1,.76-.67.74.74,0,0,1,.76.7v1.59h0V14.9a1,1,0,0,0,2,0V13.73h0V12.46a.74.74,0,0,1,.76-.67.76.76,0,0,1,.77.7Z'/></svg>"); }
  .icon-ticket      { margin-top: 5px; margin-left: 6px; background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20' style='height: 1.3em; vertical-align: sub; margin-right: 5px;'><defs><style>.cls-1{fill:%2366676b;}</style></defs><path class='cls-1' d='M18.57,16.86H1.44a.74.74,0,0,1-.74-.74V12.58a.74.74,0,0,1,.74-.74,1.84,1.84,0,1,0,0-3.69A.74.74,0,0,1,.7,7.42V3.88a.74.74,0,0,1,.74-.73H18.57a.74.74,0,0,1,.74.73V7.42a.74.74,0,0,1-.74.73,1.84,1.84,0,1,0,0,3.69.74.74,0,0,1,.74.74v3.55A.74.74,0,0,1,18.57,16.86ZM2.17,15.39H17.83V13.23a3.31,3.31,0,0,1,0-6.46V4.61H2.17V6.77a3.31,3.31,0,0,1,0,6.46Z'></path></svg>"); }

  div.helptext {
    div {
      display: flex;
      flex-direction: row;

      a {
        margin-right: 18px;
      }
    }
  }
}

// Helpkacheln
.help-center {
  .dark {
    background-color: $darkgraybackground;
    color: white;
  }

  .help-card {
    padding: 20px;
    padding-bottom: 0px;

    .card-body{
      display: flex;
      flex-direction: column;
      height: 100%;

      &.card-body-wide {
        flex-direction: row;

        .image-wrapper {
          margin-top: 0px;
          margin-bottom: 0px;
        }
      }

      .help-card-content-wide {
        margin-left: 30px;

        display: flex;
        flex-direction: column;

        .username {
          padding: 0px;
          margin-bottom: 10px;
        }

        .view-more {
          font-size: 0.75rem;

          margin-top: auto;
          margin-bottom: 10px;

          padding-right: 10px;

          a {
            font-weight: 500;

            align-items: center;
            display: flex;
            justify-content: flex-end;
            i {margin-left: 5px;}
          }
        }
      }
    }
  }
}

.image-wrapper {
  @extend .avatar-wrapper;

  flex-shrink: 0;

  box-shadow: unset;

  margin-top: 15px;
  margin-bottom: 15px;

  img {
    height: 100%;
    width: 100%;
    transition: all 0.3s ease;
  }
}

// Allgemeines

.overflow-initial{
  overflow: initial;
}

.text-right {
  text-align: right !important;
}

div.tabs.with-width-constraint {
  ul {
    display: flex;
    max-width: 100%;

    li {
      min-width: 200px;

      a {
        overflow: hidden;
        display: block;
        text-overflow: ellipsis;
      }
    }
  }
}
.overflow-initial{
    overflow: initial;
}

.tag{
    margin-right: .3em;
    margin-left: .3em;
}

.box {
  position: relative;

  p {
    color: $graytext;
  }
}

.video-box {
    .icon {
        font-size: 3rem;
        width: auto;
        height: auto;
        color: $bfsorange;
        margin-right: 1rem;
    }
}

.video-list {
    display: flex;
    flex-wrap: wrap;

    .video-item {
        width: 320px;
        height: 180px;
        margin: 1rem;
        background-size: contain;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 0 2rem;
        color: white;

        .linkicon {
            font-size: 4rem;
            height: auto;
            width: auto;
            background: radial-gradient(circle, rgba(255,255,255,1) 50%, rgba(255,255,255,0) 50%);
            border-radius: 500px;
            margin-bottom: 1rem;
        }
    }
}

iframe {
  max-width: 100%;
}

// Das sollte eigentlich automatisch über die custom colors kommen, tut es aber nicht
.switch[type=checkbox].is-bfsorange:checked + label::before,
.switch[type=checkbox].is-bfsorange:checked + label:before {
    background: $bfsorange;
}

.switch[type=checkbox] + label {
    padding-left: 0;
    display: inline-block;

    & div {
        margin-left: 4rem;
    }

    &:before,
    &:after{
        top: 50%;
        transform: translateY(-50%);
    }
}

.message a:not(.button):not(.tag):not(.dropdown-item) {
    color: $bfsorange;
    text-decoration: none;
}

button.is-white {
    color: $bfsorange !important;
}

.otp-form {
    .otp-code {
        word-break: break-all;
    }
}

.modal {
    padding: 1rem;
}
