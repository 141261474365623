@import 'portale';
@import 'kundenportal-partials/bfsTile';
@import 'kundenportal-partials/faq';
@import 'kundenportal-partials/kontakt';
@import 'kundenportal-partials/dropdown';
@import 'kundenportal-partials/verrechnungen';
@import 'kundenportal-partials/pwaBanner';

.app-wrapper {
    position: relative;
}

.newsarticle {
  a {
    i {
        margin-right: 5px;
      }
  }

  .card-body {
    display: flex;
    flex-direction: row;

    .news-image {
      width: 100%;
    }
  }
}

.flexitarif {
  .orangeCheckboxWithText {
    margin-left: 10px;
  }

  .modalitaeten {
    font-size: 80%;
  }
}

// Verrechnungen & Dokumente
.verrechnungen, .dokumente {
  .beschreibung {

    font-weight: 400 !important;

    p {
      color: $chosen-dark-grey;
      font-size: 0.9em;
      padding-top:  1rem;
      padding-bottom: 1rem;
    }
  }

  td.icon-column {
    width: 40px;
    max-width: 40px;
  }

  &.plain-text {
    margin-top: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }
}

// Sendungen
.sendungen {
  &.box {
    background-color: $darkgraybackground;

    p {
      color: white;
      &.header {
        font-weight: bold;
      }
    }

    .column {
      display: flex;
      flex-direction: row;

      i {
        color: white;
        margin-right: 20px;
        margin-top: 10px;

      }
    }
  }
}

// Einstellungen
.einstellungen {
  p {
    margin-bottom: 1.2rem;
    margin-left: 0.8rem;
    margin-right: 0.8rem;
  }

  .table {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    width: 90%;
  }

  .table tr:last-child td {
    border: 0;
  }
}

// Dashboard
.dashboard {
    .dashboard-grid{
        display: grid;
        align-items: stretch;
        justify-content: stretch;
        gap: 1em;

        @function repeat($string, $count) {
            $result: "";
            @for $i from 1 through $count {
                $result: $result + $string
            }
            @return $result;
        }

        @mixin grid-mobile {
            grid-template-areas:
                "letzte-sendung"
                "naechste-zahlung"
                "letzter-belegeingang"
                "quickfinder"
                "meine-zahlen"
                "digitale-kundenrechnung"
                "dokumenten-depot"
                "dokumente"
                "news"
                "chats"
                "tickets";
        }

        @mixin grid-two-column {
            grid-template-areas:
                "letzte-sendung naechste-zahlung"
                "letzter-belegeingang dokumenten-depot"
                repeat("quickfinder ", 2)
                repeat("meine-zahlen ", 2)
                "digitale-kundenrechnung dokumente"
                repeat("news ", 2)
                "chats tickets";
        }

        @mixin grid-widescreen {
            grid-template-areas:
                repeat("letzte-sendung ", 2) + repeat("naechste-zahlung ", 2) + repeat("letzter-belegeingang ", 2)
                repeat("quickfinder ", 6)
                repeat("meine-zahlen ", 4) + repeat("digitale-kundenrechnung ", 2)
                repeat("dokumenten-depot ", 2) + repeat("dokumente ", 2) + repeat("news ", 2)
                repeat("chats ", 3) + repeat("tickets ", 3)
        }

        @include mobile {
            grid-template-columns: 100%;
            @include grid-mobile;
        }

        @mixin two-column-layout {
            @include grid-two-column;
        }

        @include tablet {
            @include two-column-layout;
        }

        @include widescreen
        {
            @include grid-widescreen;
        }

        &>*:not(.quickfinder-grid){
            min-width: 10%;
            min-height: 200px;
            height: 100%;
        }

        &>* {
            position: relative;
        }

        .letzte-sendung-grid { grid-area: letzte-sendung; }
        .anstehende-zahlung-grid { grid-area: naechste-zahlung; }
        .letzter-belegeingang-grid { grid-area: letzter-belegeingang; }
        .quickfinder-grid { grid-area: quickfinder; }
        .meine-zahlen-grid { grid-area: meine-zahlen; }
        .digitale-kundenrechnung-grid { grid-area: digitale-kundenrechnung; }
        .chats-grid { grid-area: chats; }
        .tickets-grid { grid-area: tickets; }
        .neuste-dokumente-grid { grid-area: dokumente; }
        .dokumenten-depot-grid { grid-area: dokumenten-depot; }
        .news-grid { grid-area: news; }

        .quickfinder-box.quickfinder-dashboard {
            margin: 0;

            grid-area: quickfinder;

            .flat-card {
                background-color: $white;
                color: $body-color;
                padding-top: 0;
                padding-bottom: 0;

                .card-body-quickfinder {
                    display: flex;
                    flex-direction: row;

                    @include touch {
                        flex-direction: column;
                    }

                    @include desktop {
                        flex-direction: row;
                    }

                    .quickfinder-content {
                        flex-grow: 1;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        width: 100%;

                        @include until-widescreen {
                            flex-basis: 0;
                            margin-right: .5em;
                        }

                        .quickfinder-icon {
                            display: flex;

                            @include mobile {
                                flex-grow: 1;
                                flex-basis: 0;
                                display: flex;
                                justify-content: center;
                            }
                        }

                        .quickfinder-text {
                            flex-grow: 1;
                            display: flex;
                            padding: 1rem 0;
                            align-items: center;

                            @include until-widescreen {
                                flex-direction: column;
                                align-items: start;
                            }

                            @include widescreen {
                                flex-direction: row;
                            }

                            @include mobile {
                                flex-basis: 0;
                            }

                            .quickfinder-title {
                                font-size: 18px;
                                font-weight: bold;
                            }

                            .quickfinder-description {
                                font-size: 13px;
                                color: #9e9e9e;
                            }

                            .video-link {
                                flex-grow: 1;
                                font-size: 14px;
                                margin-right: 40px;
                                display: flex;
                                justify-content: flex-end;

                                a {
                                    display: flex;
                                    align-items: center;

                                    .play-icon {
                                        width: 32px;
                                        margin-left: .5rem;
                                    }

                                    &:hover {
                                        .play-icon {
                                            filter: saturate(0); // Färbt das icon bei hover grau
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .searchbox {
                        flex-grow: 1;

                        .quickfinder-rechnungsnummer {
                            width: 100%;
                        }

                        @include until-widescreen {
                            flex-basis: 0;
                            margin-left: .5em;
                        }

                        @include fullhd {
                            width: 500px;
                        }

                        @include widescreen-only {
                            width: 410px;
                        }

                        @include touch {
                            width: 100%;
                            padding-bottom: 1rem;
                        }
                    }
                }
            }

            a {
                white-space: nowrap;
            }
        }
    }

    margin-top: 15px;

    .flat-card.bfs-tile-card {

        .sendungs-list {
            overflow-y: auto;

            .sendung:not(:last-child) {
                margin-bottom: 1em;
            }
        }

        .meine-zahlen {

            display: flex;

            @include mobile {
                flex-direction: column;
            }

            .meine-zahlen-description {
                width: 20%;
                margin-right: 1em;
                display: flex;
                flex-direction: column;
                justify-content: center;

                align-self: start;

                b {
                    margin-bottom: .5em;
                }

                @include mobile {
                    width: 100%;
                    margin-bottom: 2em;
                }
            }

            .meine-zahlen-chart {
                flex-grow: 1;
                width: 80%;

                @include mobile {
                    width: 100%;
                }
            }
        }

        .digitale-kundenrechnung {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-around;

            .dashboard-hero {
                flex-shrink: 0
            }
        }

        .two-value-display {

            display: grid;
            grid-template-columns: repeat(1, 50px auto 1fr);
            grid-template-areas:
            "first-icon first-value first-description"
            "second-icon second-value second-description";

            @include mobile {
                grid-template-columns: repeat(1, 10% 50px auto 50%);
                grid-template-areas:
                "x first-icon first-value first-description"
                "x second-icon second-value second-description";
            }

            @include widescreen {
                grid-template-columns: repeat(2, 50px auto 1fr);
                grid-template-areas:
                    "first-icon first-value first-description second-icon second-value second-description";
            }

            .first-icon { grid-area: first-icon; }
            .first-value { grid-area: first-value;}
            .first-description { grid-area: first-description; }

            .second-icon { grid-area: second-icon; }
            .second-value { grid-area: second-value; }
            .second-description { grid-area: second-description; }

            &>*{
                align-self: center;
                padding-right: 1rem;
            }

            .dashboard-icon-small {
                padding-right: .5rem;
            }
        }

        .dokumente {
            flex-grow: 1;
            max-width: 100%;

            ul {
                li {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: stretch;

                    &:not(:last-child) {
                        margin-bottom: .5em;
                    }

                    .dokument-nummer {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex-shrink: 0;

                        background-color: lightgray;
                        width: 1.5em;
                        height: 1.5em;

                        border-radius: 100px;

                        margin-right: 1em;
                    }

                    .dokument-data {
                        flex-grow: 0;
                        white-space: nowrap;
                        overflow: hidden;

                        .dokument-name {
                            text-overflow: ellipsis;
                            overflow: hidden;
                        }
                    }

                    .dokument-download {
                        margin-left: .5em;
                    }
                }
            }
        }

        .dokumentendepot {
            width: 100%;
            display: grid;
            grid-template:
                "a a"
                "a a";

            .item {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin: .5em 0;

                .tag {
                    min-width: 100px;
                }
            }
        }

        .news {

            line-height: 1.2;

            li {
                position: relative;

                &:not(:last-child) {
                    margin-bottom: 1rem;
                }

                .news-text {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }

                .news-link {
                    position: absolute;
                    right: 0;
                    bottom: 0;

                    padding-left: 5rem;
                    background: rgb(255,255,255);
                    background: linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 5rem);
                }
            }
        }

        .events-easter {
            height: 100%; // Damit der Text ganz oben steht
            .found-eggs {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;

                margin: 1rem 0;

                img {
                    width: 48px;
                    height: 48px;
                }
            }
        }

    }

    .modal-wide {
        width: 60vw;
        min-width: min(640px, 100%);

        .pdf {
            height: 65vh;
            width: 100%;
        }
    }
}

.is-rounded {
    border-radius: 9999px;
}

.easteregg {
    width: 48px;
    height: 48px;

    z-index: 10;

    position: absolute;
    top: 0;
    left: 0;

    margin-top: -24px;
    margin-left: -24px;

    &.clickable {
        background-image: url('public/events/easter/egg_BFS_target_default.svg');

        &:hover {
            background-image: url('public/events/easter/egg_BFS_target_mouseover_ispressed_box.svg');
        }

        &.found {
            background-image: url('public/events/easter/egg_BFS_target_active_issolved.svg');
        }
    }
}

.banner-editor {

    .card-editor {
        overflow: visible; // Für die DatePicker
    }

    .image-upload {
        .image-preview {
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: start;
            img {
                max-height: 300px;
                margin-bottom: .5rem;
            }
        }
    }

    .preview {
        margin-bottom: 20px;
    }
}

@mixin banner-background-mobile {
    .banner-background-container {
        width: 100%;
        height: unset;
        aspect-ratio: 4/3;

        left: 0;
        top: 0;

        background-position: 50% 10000px, 50% bottom;
    }

    .flat-card.bfs-tile-card {
        .card-header {
            width:100%;
            aspect-ratio: 4/3;
            .notification-title {
                display: flex;
                flex-direction: column;
                align-items: start;
                justify-content: space-between;

                .mobile-spacer {
                    width: 100%;
                    aspect-ratio: 4/3;
                    margin-bottom: 2rem;
                }
            }
        }

        .card-body {
            .bfs-tile-content {
                max-width: 100%;
            }
        }
    }

    &:not(.has-background-image-mobile) {
        .bfs-tile-card {
            .card-header {
                aspect-ratio: unset;

                .notification-flag-placeholder {
                    min-height: unset;
                }

                .mobile-spacer {
                    display: none;
                }
            }
        }
    }
}

.notification-tile {
    position: relative;
    overflow: hidden;

    margin: 1rem 0;

    .banner-background-container {
        position: absolute;
        right: 0;
        top: 0;
        width: 33%;
        height: 100%;
        background-position: left center, 0 100000px;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .bfs-tile-header-title {
        width: 100%;

        .notification-flag-placeholder {
            min-height: 28px;
        }

        .notification-flag {
            color: white;
        }
    }

    .bfs-tile-content {
        strong {
            color: unset;
        }
    }

    &.has-background-image-desktop {
        .bfs-tile-card {
            .bfs-tile-content {
                max-width: 65%;
            }
        }
    }

    @include mobile {
        @include banner-background-mobile;
    }

    &.banner-preview-mobile {
        @include banner-background-mobile;
    }

    &.banner-image-only {
        padding: 0;

        display: flex;
        flex-direction: column;
        justify-content: center;

        .banner-mobile {
            display: none;
        }

        .bfs-tile-card {
            position: absolute;
        }

        &.banner-preview-mobile {
            .banner-desktop {
                display: none;
            }
            .banner-mobile {
                display: block;
            }
        }

        @include mobile {
            .banner-desktop {
                display: none;
            }
            .banner-mobile {
                display: block;
            }
        }
    }
}

.easter-2024 {
    .notification-tile {
        background-image: url('public/events/easter/BFS_Kundenportal_TopNews_Ostern_Desktop_20240213.png');
        background-position: right;
        background-size: cover;

        @include desktop-only {
            background-image: url('public/events/easter/BFS_Kundenportal_TopNews_Ostern_769px_20240306.png');
        }

        @include touch {
            background-image: url('public/events/easter/BFS_Kundenportal_TopNews_Ostern_Mobile_20240213.png');
            background-position: top;
            background-size: cover;

            .bfs-tile-header-title {
                width: 100%;
                aspect-ratio: 1;
                min-height: 350px;

                .notification-title {
                    flex-direction: column;
                    align-items: start;
                    justify-content: space-between;
                    height: 100%;
                }
            }
        }

        .notification-title-text {
            color: #545454;
        }

        .content {
            color: #545454;

            @include desktop {
                max-width: 50%;
            }
        }
    }
}
